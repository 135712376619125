@import "../../../../components/sass/variables.scss";
@import "../../../../components/sass/mixins.scss";

.animate-hamburger {
  transform: rotate(45deg);
  .line2 {
    transform: translateX(15vw);
    background-color: red;

    @include mq($xls) {
      transform: translateX(15vh);
    }
  }

  .line3 {
    position: relative;
    top: -12.5px;
    transform: rotate(90deg);
  }

  .line1 {
    position: relative;
    top: 12.5px;
    // transform: rotate(-45deg);
  }
}

.interview:hover{
 color:#000 !important
}
.fixed-menu {
  position: fixed !important;
  top: 0 !important;
  min-width: 100vw;
  z-index: 10;
  padding: 20px;
  backdrop-filter: blur(31px);
  // background-color: #B9035857;
  border-radius: 0px 0px 20px 20px;
  transition: background-color 2s;
}
.scrolled{
 background-color: rgba(0,0,0,.8);
}

#links {
  min-height: 100vh;
  background-color: $black_ligtened;
  min-width: 100%;
  padding: 0px;
  // position: fixed;

  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  overflow-y: scroll;
  @include scroll-bar-hidden;

  @include mq($xls) {
    flex-direction: column;
  }
  a {
    color: $white;
  }

  .nav {
    min-width: 49%;
    max-width: 49%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: grid;
    place-items: center;
    pointer-events: none;

    @include mq($xls) {
      min-width: 100%;
      height: auto !important;
      min-height: 0px;
      padding-top: 75px;
      padding-bottom: 45px;
    }

    .link-address {
      // margin-top:1.5vh;
      margin-bottom: 2.0vh;
      height: auto;
      pointer-events: auto;

      .number {
        margin-right: 10px;
        @include poppins-regular-16;
        font-family: $Heebo_Light;
        text-rendering: optimizeLegibility;
        color: $gray_color;
        font-size: 16px;

        @include mq($xlg) {
          font-family: $Heebo_Regular;
          font-weight: 100;
        }
      }

      .actual-link {
        @include poppins-regular-16;
        color: $white;
        font-family: $GTSectraDisplay_Bold;
        font-size: 1.8em;
        user-select: none;
        text-rendering: optimizeLegibility;
      }

      .active {
        color: $red;

        &:hover {
          color: $red;
        }
      }

      .address-location {
        margin-bottom: 2vh;
        // display:flex;
        flex-direction: row;
      }
    }
  }

  .location {
    min-width: 30%;
    height: 100vh;
    display: grid;
    place-items: center;

    @include mq($md) {
      width: 100%;
      height: auto;
    }
    .address-location {
      margin-bottom: 2vh;
      // display:flex;
      flex-direction: row;
    }

    .social-icon-time {
      display: flex;
      flex-direction: row;

      .address-items {
        flex: auto;
        font-family: $Heebo_Light;
        text-rendering: optimizeLegibility;
        font-size: 12px;
        color: $white;
        line-height: 2;

        @include mq($xlg) {
          font-family: $Heebo_Regular;
        }

        // margin-right:3vh;
      }
    }

    .address-location-below {
      display: flex;
      flex-direction: row;

      .text-inside {
        font-family: $Heebo_Light;
        text-rendering: optimizeLegibility;
        font-size: 18px;
        color: $white;
        line-height: 2;

        @include mq($xlg) {
          font-family: $Heebo_Regular;
        }
      }

      .text-number {
        font-family: $Heebo_Light;
        text-rendering: optimizeLegibility;
        font-size: 9.6px;
        color: $gray_color;
        margin-right: 7px;

        @include mq($xlg) {
          font-family: $Heebo_Regular;
        }
      }

      .right-text {
        float: right;
        flex: auto;
      }

      .left-text {
        flex: auto;
        margin-right: 3vh;
      }
    }

    .title {
      font-family: $Heebo_Light;
      text-rendering: optimizeLegibility;
      color: $white;
      font-size: 18px;
      text-decoration: none;
      line-height: 2;

      @include mq($xlg) {
        font-family: $Heebo_Regular;
        font-weight: 100;
      }
    }

    .supporting {
      font-size: 12px;
      line-height: 2;
      text-decoration: none;
      color: $gray_color;
      font-family: $Heebo_Light;
      text-rendering: optimizeLegibility;

      @include mq($xlg) {
        font-family: $Heebo_Regular;
      }
    }
  }

  .line-art {
    flex: auto;
    max-width: 0.5px;
    min-width: 0.5px;
    background-color: $gray_color;
    opacity: 0.1;
    height: 100vh;

    @include mq($xls) {
      display: none;
    }
  }

  .cancel-btn {
    float: left;
    z-index: 10;
    position: absolute;
    // top:32px;
    left: 0px;
    width: 100%;

    #cancel-img {
      width: 50px;
      height: auto;
      float: right;
      opacity: 0.6;
    }
  }
}

#service-link {
  display: none;
  width: 0px;
  overflow: hidden;
  height: 0;
}
