@import "../../components/sass/variables.scss";
@import "../../components/sass/mixins.scss";

#home-page {
  // width: 100vw;
  // display: grid;
  // place-items: center;
  max-width:100vw;
  overflow-x: hidden;
  // .home-container {
  //   max-width: 2000px;
  // }
}
