@import "../../../../components/sass/variables.scss";
@import "../../../../components/sass/mixins.scss";

#share {
    position: fixed;
    z-index: 300;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba($color: #141414, $alpha: .44);
    display: grid;
    place-items: center;
    top:0;
    left:0;
    input::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    .sharelist{
        height: 50px;
        width: 350px;
        //padding-left: 10px;
        @include mq($xls){
            width: 200px;
           }
        button{
            margin-left: 15px;
        }
    }
    .social-media-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }
    .panel {
        min-width: 460px;
        max-width: 500px;
        height: auto;
        border-radius: 9px;
        background: #121212;
        padding:9px;
        margin-bottom: 20px;
        @include mq($xls){
            min-width: 300px;
        max-width: 300px;
          }
        .top-button {
            margin-bottom: 17px;

            .title {
                font-family: $open-sans-semi-bold;
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.17em;
                line-height: 1;
                text-align: left;
                color: $music-page-white;
                align-self: center;
            }

            svg {
                align-self: center;
                cursor: pointer;
            }
        }

        .header-img {
            min-width: 100%;
            height: 15vw;
            border-radius: 9px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            @include background-img;
            margin-bottom: 20px;
        }

        .prerequist {
            max-width: 100%;
            font-family: $open-sans-light;
            font-weight:bold;
            font-size: 22px;
            line-height: 25.2px;
            text-align: left;
            color: #dbdbdb;
            margin-bottom: 20px;
        }

    }
}
.arrows{
    position: absolute;
}
.right-arrow{
    right: auto;
}
.desktop{
    display:contents;
    @include mq($xls){
       display: none;
      }
}
.mobile{
    display: none;
    @include mq($xls){
        display:contents;
       }
       .arrows{
        position:relative;
    }

}
.cpy-btn:focus{
 color: blue;
}