@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./components/sass/variables.scss";
@import "./components/sass/mixins.scss";
@import "./components/fonts/font.scss";
// needs refactoring
#root {
  max-width: 100vw;
  overflow-x: hidden;
}
.col-md-9{
  padding-right: 0 !important;
}
#lyrics{
  p{
    margin-bottom: 0.1rem !important;
    line-height: 0.5 !important;
  }
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden !important;
  display: none !important;
  pointer-events: none !important;
  position: absolute !important;
  right: 0;
}
.center-div{
  width: auto;
  text-align: center;
  margin: auto;
}
.react-jinke-music-player-main{
  z-index:2000;
  position: relative;
}
.input-group {
  @include mq($xls){
    width: (100vw/1.5);
  }
  place-items: center;
  border-radius: 9px;
  background: rgba($color: #383838, $alpha: 0.39);
  width: (100vw/2);
  height: (60px / $divider);
  border: none;
  padding-left: 15px;

 

  input {
      width: calc(100% - 30px);
      background: rgba($color: #383838, $alpha: 0.0);
      border: none;
      padding-left: 15px;
      font-family: $open-sans-regular;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.17em;
      line-height: 1;
      text-align: left;
      color: #bfbfbf;

      &:focus {
          outline: none;
      }

      &:input-placeholder {
          font-family: $open-sans-regular;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.17em;
          line-height: 1;
          text-align: left;
          color: #dbdbdb;
      }

  }

}

.btn-loading,
.btn-error,
.btn-success {
  background-color: #7171ff;
  height: (60px / $divider);
  color: $white;
  font-weight: 300;
  font-size: (18px / $divider);
  line-height: (30px / $divider);
  text-align: left;
  margin-bottom: (30px / $divider);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: (9px / $divider);
  font-family: $open-sans-semi-bold;
  font-weight: 500;

  .text {
    display: grid;
    place-items: center;
  }
}

.btn-error {
  background-color: #ff7171;
}

.btn-success {
  background-color: #5dd97c;
  color: #000;
}

.card {
  background-color: rgba($color: #000000, $alpha: 0);
  margin-left: 7px !important;
  margin-right: 7px !important;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
}
html,
*,
body {
  scroll-behavior: smooth;
  // -webkit-appearance: none;
}
body {
  background-color: #000;
  @include scroll-bar;
  max-width: 100vw;
  overflow-x: hidden;
  a {
    text-decoration: none !important;
  }
  .wrap {
    @include mq($xls) {
      width: 100% !important;
      flex-wrap: wrap !important;
      max-width: 100vw !important;
      overflow: scroll !important;
    }
  }
}

svg{
  cursor: pointer;
}
.pointer{
  cursor: pointer !important;
}
