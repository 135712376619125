.workings-card-wrapper {
    display: flex;
    background-size: contain;
    flex-direction: column;
    align-items: center;
    color: black;
    border-radius: 1vw;
    padding: 1.5vw;

    .background-image {
        position: relative;
        z-index: -1;
    }

    .workings-icon {
        margin-bottom: 2vw;

    }

    h1 {
        align-self: flex-end;
    }

    p {
        font-size: 0.8vw;
    }
}