@import '../../../../components/sass/variables.scss';
@import '../../../../components/sass/mixins.scss';

#cta-production {
    display: grid;
    place-items: center;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 31px;
    margin-bottom: 5em;

    .cta-title {
        font-family: $SFProDisplay_Bold;
        font-weight: bold;
        font-size: 4.9375em;
        line-height: 1.2;
        text-align: left;
        color: $white;
        margin-bottom: 9px;
        @include mq($md) {
            font-size: 3.5em;
        }
        @include mq($xls){
            text-align: center;
            line-height: 1.1;
        }
    }

    .cta-text {
        max-width: 35%;
        font-family: $SFProDisplay_Regular;
        font-weight: normal;
        font-size: 1.3;
        text-align: center;
        color: $white;
        margin-bottom: 20px;

        @include mq($lg) {
            max-width: 50%;
        }

        @include mq($md) {
            max-width: 75%;
        }
        @include mq($xls){
            max-width: 90%;
        }
    }

    .btn-cta {
        border-radius: 30px;
        background: $white;
        min-height: 60px;
        display: grid;
        place-items: center;
        padding-left: 35px;
        padding-right: 35px;
        font-family: $SFProDisplay_Medium;
        font-weight: 500;
        font-size: 19.75px;
        text-align: left;
        color: $pure_black;

        .d-flex {
            gap: 20px;
            align-items: center;
        }
    }
}