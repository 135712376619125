@import "../../../../../components/sass/mixins.scss";
@import "../../../../../components/sass/variables.scss";

.compare-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h1 {
    font-size: 10vw;
    margin-bottom: -5vh;
    color: rgba(255, 255, 255, 0.212);

    @include mq($md) {
      margin-bottom: -2vh;
      display: none;
    }
  }

  .input-content {
    background-image: url($root+"/assets/images/mesh1.jpeg"), url($root+"/assets/images/close-up-gray-glitter-textured-background-min_1+(1).webp");
    background-blend-mode: soft-light;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    gap: 1vw;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: max-content 1fr;
    height: 100%;
    border-radius: 10vw 2vw 1px 1px;

    @include mq($md) {
      display: flex;
      flex-direction: column;
      border-radius: 30px;
    }

    .input-image {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      background-image: url("./assets/NoPath\ -\ Copy\ \(3\)@2x.png");
      background-size: 105%;
      background-position: center;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;

      svg {
        fill: none;
        stroke: #f8107d;
        stroke-width: 4px;
        grid-column-start: 1;
        grid-row-start: 1;
        overflow: visible;
        stroke-dasharray: 125;
        aspect-ratio: 1/1;
      }

      img {
        width: 5vw;
        grid-column-start: 1;
        grid-row-start: 1;
      }

      @include mq($md) {
        align-self: center;
        width: 250px;
        height: 250px;
        margin-top: 40px;

        img {
          width: 10vw;
        }
      }

      @include mq($xls) {
        width: 290px;
        height: 290px;
      }
    }

    .input-wave {
      grid-column: 1/3;
      width: 90%;
      height: max-content;
      margin: auto;
      margin-bottom: 2vh;

      img {
        height: max-content;
        width: 100%;
      }
    }

    .input-text {
      margin-right: 1.5vw;
      justify-self: center;
      align-self: center;
      padding-top: 30px;
      padding-right: 20px;

      @include mq($md) {
        margin-right: 0vw;
        padding-left: 20px;
      }

      @include mq($xls) {
        margin-right: 0vw;
        padding-left: 15px;
      }

      h2 {
        font-family: $SFProDisplay_Bold;
        font-size: 3.0625em;
        text-align: left;

        @include mq($lg) {
          font-size: 2.5em;
        }
      }

      h5 {
        font-family: $SFProDisplay_Regular;
        font-size: 1.5em;
        opacity: 1;
        text-align: left;
      }

      p {
        font-family: $SFProDisplay_Regular;
        font-size: 1.234375em;
        opacity: 1;
        text-align: left;
      }
    }
  }
}

.compare-output {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h1 {
    align-self: flex-start;
    font-size: 10vw;
    margin-bottom: -5vh;
    color: rgba(255, 255, 255, 0.212);

    @include mq($md) {
      margin-bottom: -2vh;
      display: none;
    }
  }

  .output-content {
    background-image: url($root+"/assets/images/mesh2.jpeg"), url($root+"/assets/images/close-up-gray-glitter-textured-background-min_1+(1).webp");
    background-blend-mode: soft-light;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    gap: 1vw;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: max-content 1fr;
    border-radius: 2vw 10vw 1px 1px;
    height: 100%;

    @include mq($md) {
      display: flex;
      flex-direction: column;
      border-radius: 30px;
    }

    .output-image {
      // overflow: hidden;
      grid-column: 2/2;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      background-image: url("./assets/NoPath - Copy (3)-1@2x.png");
      background-size: 105%;
      background-position: center;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;

      svg {
        fill: none;
        stroke: #106df8;
        stroke-width: 4px;
        grid-column-start: 1;
        grid-row-start: 1;
        overflow: visible;
        stroke-dasharray: 400;
        aspect-ratio: 1/1;
      }

      img {
        width: 5vw;
        grid-column-start: 1;
        grid-row-start: 1;
      }

      @include mq($md) {
        align-self: center;
        width: 250px;
        height: 250px;
        margin-top: 40px;

        img {
          width: 10vw;
        }
      }

      @include mq($xls) {
        width: 290px;
        height: 290px;
      }
    }

    .output-wave {
      grid-column: 1/3;
      width: 90%;
      margin: auto;
      margin-bottom: 2vh;

      img {
        width: 100%;
      }
    }

    .output-text {
      grid-row: 1/2;
      grid-column: 1/2;
      margin-left: 1.5vw;
      align-self: center;
      justify-self: right;
      padding-top: 30px;
      padding-left: 20px;

      @include mq($md) {
        margin-left: 0px;
      }

      @include mq($xls) {
        padding-left: 10px;
      }

      h2 {
        font-family: $SFProDisplay_Bold;
        font-size: 3.0625em;
        text-align: left;

        @include mq($lg) {
          font-size: 2.5em;
        }
      }

      h5 {
        font-family: $SFProDisplay_Regular;
        font-size: 1.5em;
        opacity: 1;
        text-align: left;
      }

      p {
        font-family: $SFProDisplay_Regular;
        font-size: 1.234375em;
        opacity: 1;
        text-align: left;
      }
    }
  }
}