.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: #f8107d;
}
.react-jinke-music-player-main svg:active,
.react-jinke-music-player-main svg:hover {
  color: #f8107d;
}
.audio-lists-panel-content .audio-item.playing,
.audio-lists-panel-content .audio-item.playing svg {
  color: #f8107d;
}
.audio-lists-panel-content
  .audio-item:active
  .group:not([class=".player-delete"])
  svg,
.audio-lists-panel-content
  .audio-item:hover
  .group:not([class=".player-delete"])
  svg {
  color: #f8107d;
}
.react-jinke-music-player-main .music-player-panel {
  background-color: #383838;
  bottom: 0;
  box-shadow: 0 0 3px none;
  color: #fff;
  height: 80px;
  left: 0vw;
  right: 0 !important;
  position: fixed;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 99;
  border-radius: 0px 0px 0px 0px;
  max-width: 100vw !important;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-content {
  background-repeat: no-repeat;
  background-size: cover !important;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 34 77 / 5%);
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  width: 50px;
  width: 60px;
  height: 60px;
  /* background: url(...); */
  border: 2px solid #f8107d;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  animation: none 15s linear infinite;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  width: 100%;
  font-family: OpenSans-SemiBold;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #fff;
}

.singer-link{
  color:#fff;
  cursor: pointer;
}
.singer-link:hover{
  color: #f8107d;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration {
  flex-basis: 5%;
  font-size: 12px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  font-family: OpenSans-SemiBold;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #fff;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  box-shadow: 0 0 1px 1px hsl(0deg 0% 100% / 2%);
  height: 28px;
  min-width: 60px;
  padding: 0 10px;
  position: relative;
  transition: color, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  -webkit-user-select: none;
  user-select: none;
  display: none;
}
.react-jinke-music-player-main .music-player-panel .panel-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .react-jinke-music-player-main {
    position: fixed;
    z-index: 100000;
  }
}
