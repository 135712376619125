@import "../../../../components/sass/mixins.scss";
@import "../../../../components/sass/variables.scss";

.landing-page-wrapper {
  position: relative;
  background-image: url($root+"assets/images//richard-horvath-is8jWT-mvng-unsplash.jpg");
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding-top: 100px;
  .row {
    place-items: center;
    align-content: center;
  }
  .landing-title {
    @include mq($xls) {
      max-width: calc(100vw - 30px);
    }
  }
  .main-title {
    font-size: 4.9375em;
    font-family: $SFProDisplay_Bold;
    font-weight: bold;
    line-height: 1.1em;
    text-align: left;
    color: $white;
    @include mq($xlg) {
      font-size: 4.9375em;
    }
    @include mq($lg) {
      font-size: 3.9375em;
    }
    @include mq($md) {
      font-size: 3em;
    }
  }
  .services,
  .catch-flase {
    margin-top: 20px;
    color: $white;
    font-family: $SFProDisplay_Bold;
    font-weight: 900;
    max-width: 100%;
    @include mq($md) {
      font-size: 1.3em;
    }
  }
  .text {
    font-family: $SFProDisplay_Regular;
    font-size: 1.234375em;
    line-height: 1.16;
    margin-bottom: 18px;
    @include mq($xls) {
      font-size: 1em;
    }
  }

  .landing-page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 93%;
    margin: auto;
    margin-bottom: 15vh;
    color: white;
    @include mq($lg) {
      grid-template-columns: 1fr 0.7fr;
      width: 100%;
    }
    @include mq($xls) {
      grid-template-columns: unset;
      width: 100%;
    }

    h1 {
      font-family: $SFProDisplay_Bold;
      font-size: 4.9375em;
      line-height: 1.1;
      @include mq($lg) {
        font-size: 4em;
      }
      @include mq($md) {
        font-size: 4.9em;
      }
      @include mq($xls) {
        font-size: 3em;
      }
      @include mq($xms) {
        font-size: 2.9em;
      }
    }

    p {
      font-family: $SFProDisplay_Regular;
      font-size: 1.234375em;
      line-height: 1.16;
      margin-bottom: 18px;
      @include mq($xls) {
        font-size: 1em;
      }
    }

    .landing-button-wrapper {
      display: flex;
      flex-direction: row;
      gap: 20px;
      // justify-content: space-between;

      .landing-button {
        font-family: $SFProDisplay_Medium;
        border-radius: 100px;
        min-height: 60px;
        padding: 0px 25px;
        background-color: $white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1.2vw;
        color: #000;
        @include mq($lg) {
          font-size: 0.8em;
          min-height: 50px;
        }
        @include mq($md) {
          margin-right: 30px;
        }
        @include mq($xls) {
          min-width: 40vw;
        }

        img {
          margin-right: 2vw;
          height: max-content;
          width: 1.5vw;
          color: black;

          @include mq($md) {
            width: 3vw;
            margin-left: 2vw;
            margin-right: 2vw;
            margin-top: 2vw;
            margin-bottom: 2vw;
          }
        }
      }

      .landing-button:hover {
        background-color: rgb(210, 210, 210);
      }
    }
  }
}
