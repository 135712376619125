.testimonial {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 0px;
    margin-bottom: 0px;
    :global .single-testimonial {
        width: calc((100%) - 20px);
        border-radius: 16px;
        background: #fff;
        margin-bottom: 42px;
        margin-top: 43px;

        @media screen and (max-width: 768px) {
            width: calc((100%) - 20px);
        }


        .user-image {
            width: 85px;
            height: 85px;
            background-color: gray;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            position: absolute;
            transform: translateX(30px) translateY(-(85px/2));
        }

        .testimonial-svg {
            position: absolute;
            transform: translateX(50px) translateY(60px);
            // z-index: -1;
        }

        .testimonial {
            width: 100%;
            font-family: "SFPRODISPLAYREGULAR";
            font-weight: normal;
            font-size: 0.875em;
            text-align: left;
            color: #2c323f;
            padding: 60px 20px 20px 20px;
        }

        .user-details {
            display: flex;
            padding: 9px 20px 15px 20px;
            justify-content: space-between;
            width: 100%;
            align-content: center;
            align-items: center;

            .username {
                font-family: "SFPRODISPLAYBOLD";
                font-weight: 900;
                font-size: 1.375em;
                line-height: 1;
                text-align: left;
                color: #2c323f;
            }

            .occupation {
                font-family: "SFPRODISPLAYREGULAR";
                font-weight: normal;
                font-size: 0.875em;
                line-height: 1;
                text-align: left;
                color: #2c323f;
            }

            .stars {
                display: flex;
                gap: 12px;
            }
        }
    }
        :global {
            .custom-dot-list-style {
                gap: 15px;
            }
            .carousel-container{
                width:100%;
            }
        }
}

.customDot {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    background: #fff;
    border: 2px solid #F8107D;
    border-radius: 50%;
    cursor: pointer;
}

.active {
    background: #F8107D;
}