.production-page-wrapper {
    background-color: #19245A;
    height: 100vh;
}
.production-preloader{
    min-width:100vw;
    min-height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:10000;
    background-color: black;
}