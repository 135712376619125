$red:#F8107D;
$root:"https://leerecs.com";
$gray_color:#EFE9F8;
$black_text:#0f0f0f;
$black-text-player:#383838;
$main-black:#292929;
$black_ligtened:lighten(#09090D, 6);
$panel_color:#121212;
$white:#ffffff;
$pure_black:#000;
$gray-main-color:#bfbfbf;
$music-page-white:#DBDBDB;
$open-sans-bold:OpenSans-Bold;
$open-sans-extra-bold:OpenSans-ExtraBold;
$open-sans-light:OpenSans-Light;
$open-sans-regular:OpenSans-Regular;
$open-sans-semi-bold:OpenSans-SemiBold;
$poppins-extra-bold:Poppins-extra-bold;
$philosopher-bold:Philosopher-Bold;
$philosopher-regular:Philosopher-Regular;
$gilroy-bold:Gilroy_Bold;
$gilroy-medium:Gilroy_Medium;
$GTSectraDisplay_Bold:GTSectraDisplay_Bold;
$GTSectraDisplay_Regular:GTSectraDisplay_Regular;
$Heebo_Light:Heebo_Light;
$Heebo_Medium:Heebo_Medium;
$Heebo_Regular:Heebo_Regular;
$SFProDisplay_Medium:SFPRODISPLAYMEDIUM;
$SFProDisplay_Semi_Bold:SFPRODISPLAYSEMIBOLD;;
$SFProDisplay_Bold:SFPRODISPLAYBOLD;
$SFProDisplay_Regular:SFPRODISPLAYREGULAR;
$SFProDisplay_Thin:SFProDisplay_Thin;
$small-device:500px;
$xxs:320px;
$xxsh:420px;
$xls:600px;
$xlsb:692;
$xlsh:500px;
$xms:375px;
$md:780px;
$lgh:930px;
$lg:1024px;
$lgd:1166px;
$xlg:1440px;
$xxlg:5000px;
$headervh:100vh;
$header-padding:10vw;
$divider:(4/3);
$xd-width:1920;
$xd-height:1080;
$width-of-container:(85px/$divider);
$menu-height:54px;

