@font-face {
    font-family: OpenSans-Bold;
    src: url("./Poppins-Bold.ttf");
}
@font-face {
    font-family: OpenSans-ExtraBold;
    src: url("./Poppins-ExtraBold.ttf");
}
@font-face {
    font-family: OpenSans-Light;
    src: url("./Poppins-Light.ttf");
}
@font-face {
    font-family: OpenSans-Regular;
    src: url("./Poppins-Regular.ttf");
}
@font-face {
    font-family: OpenSans-SemiBold;
    src: url("./Poppins-SemiBold.ttf");
}
@font-face {
    font-family: Poppins-extra-bold;
    src: url("./Poppins-ExtraBold.ttf");
}
@font-face {
    font-family: Philosopher-Bold;
    src: url("./Philosopher-Bold.ttf");
}
@font-face {
    font-family: Philosopher-Regular;
    src: url("./Philosopher-Regular.ttf");
}
@font-face {
    font-family: Gilroy_Bold;
    src: url("./Gilroy-Bold.ttf");
}

@font-face {
    font-family: Gilroy_Medium;
    src: url("./Gilroy-Medium.ttf");
}

@font-face {
    font-family: GTSectraDisplay_Bold;
    src: url("./GTSectraDisplay-Bold.ttf");
}

@font-face {
    font-family: GTSectraDisplay_Regular;
    src: url("./GTSectraDisplay-Regular.ttf");
}

@font-face {
    font-family: Heebo_Light;
    src: url("./Heebo-Light.ttf");
}

@font-face {
    font-family: Heebo_Medium;
    src: url("./Heebo-Medium.ttf");
}

@font-face {
    font-family: Heebo_Regular;
    src: url("./Heebo-Regular.ttf");
}

@font-face {
    font-family: SFPRODISPLAYBOLD;
    src: url("./SFProDisplay-Heavy.ttf");
}

@font-face {
    font-family: SFPRODISPLAYMEDIUM;
    src: url("./SFProDisplay-Medium.ttf");
}
@font-face {
    font-family: SFPRODISPLAYSEMIBOLD;
    src: url("./SFProDisplay-Semibold.ttf");
}

@font-face {
    font-family: SFPRODISPLAYREGULAR;
    src: url("./SFProDisplay-Regular.ttf");
}

@font-face {
    font-family: SFProDisplay_Thin;
    src: url("./SFProDisplay-Thin.ttf");
}