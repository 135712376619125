@import "../../../../components/sass/variables.scss";
@import "../../../../components/sass/mixins.scss";
#current-music {
  @keyframes shine {
    to {
      background-position-x: -300%;
    }
  }
  max-width:100%;
  min-height:100%;
  display:grid;
  align-content: center;
  @include mq($md){
    min-height: auto;
  }
  svg {
    min-width: 35px;
    height: auto;
    margin-right: 15px;
    margin-left: 15px;
    @include mq($xls) {
      display: none;
    }
  }
  .currentMusic {
    position: relative;
    border-radius: 20px;
    min-height: 70vh;
    min-width: 25vw;
    @include mq($lg) {
      min-width: 40vw;
    }
    @include mq($md) {
      min-width: 40vw;
    }
    @include mq($xls) {
      min-width:100%;
    }
    @include background-img;
    display: grid;
    place-items: center;
    margin-bottom: 20px !important;
  }
  .cardPreloader {
    border-radius: 20px;
    min-height: 70vh;
    min-width: 25vw;
    @include mq($lg) {
      min-width: 40vw;
    }
    @include mq($md) {
      min-width: 40vw;
    }
    @include mq($xls) {
      min-width: 100%;
    }
    background-color: lighten($color: #000000, $amount: 6);
    @include background-img;
    border-radius: 20px;
    background-color: rgba($color: #F8107D, $alpha: 1);
    background: linear-gradient(
      110deg,
      rgba($color: #F8107D, $alpha: 0.48) 8%,
      rgba($color: #F8107D, $alpha: 0.88) 18%,
      rgba($color: #F8107D, $alpha: 0.48) 33%
    );
    background-size: 300% 300%;
    background-blend-mode: multiply;
    animation: 3s shine linear infinite;
    display: grid;
    place-items: center;
    margin-bottom: 20px !important;
  }
  .carousel-circle {
    width: 100%;
    display: grid;
    place-items: center;
    .rounded-circle {
      height: 20px;
      width: 20px;
      margin-right: 20px;
      border: 2px solid $white;
      @include transition;
      cursor: pointer;
    }
    .active {
      border: 2px solid $red;
      background-color: $red;
    }
  }
}
