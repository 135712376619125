@import "../../../../components/sass/variables.scss";
@import "../../../../components/sass/mixins.scss";

//wrapper

#events {
  position: relative;
  width: 100%;
  padding: 2em;
  padding-bottom: 0px;
  @include mq($md) {
    height: auto;
  }
  @include mq($xxlg) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @include mq($xlg) {
    padding: 30px;
  }

  @include mq($lg) {
    padding: 15px;
  }
  .text {
    padding: 0.8em;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 2%;
    left: 0;
    width: 100%;
    max-width: 100%;
    backdrop-filter: blur(5px);
    border-radius: 0 20px 20px 20px;
    line-height: 1em;

    & > span {
      font-size: 0.7em;
      font-weight: bold;
    }

    @include mq($md) {
      bottom: 0;
      border-radius: 0 5px 5px 5px;
      width: 96%;
      left: 2%;
    }
  }
  .header {
    position: relative;
    display: flex;

    @include mq($md) {
      flex-direction: column;
    }
  }

  //title box

  .main-title {
    font-size: 4em;
    font-family: $GTSectraDisplay_Bold;
    font-weight: bold;
    line-height: 1.1em;
    text-align: left;
    color: $white;

    @include mq($md) {
      font-size: 3em;
    }
  }

  .description-text {
    margin-bottom: 2em;
    color: $white;
    font-size: 1.2em;
  }

  //pagination

  .pagination {
    .big-text-wrapper {
      position: absolute;
      top: 1.5em;
      right: 0;
      max-height: 15em;
      z-index: 1;
      overflow: hidden;

      //   position: relative;
      //   top: -3em;
      .music-text {
        text-align: right;
        font-family: $Heebo_Medium;
        font-size: 12em;
        max-height: 200px;
        line-height: 0.9;
        color: black;
        -webkit-text-fill-color: rgba(0, 0, 0, 0);
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(255, 255, 255, 0.16);
        background: transparent;
        // width: 714.76px;
        height: 201.29px;

        @include mq($lg) {
          font-size: 8em;
          height: 150.29px;
          max-height: 150.29px;
        }
      }

      @include mq($md) {
        display: none;
      }
    }

    //   border: 2px solid yellow;
    width: 50%;

    @include mq($md) {
      position: unset;
      width: 70%;
      // border: 2px solid yellow;
      margin-bottom: 2em;
    }

    .arrows {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      column-gap: 4em;
      img {
        height: 30px;
        width: auto;
        cursor: pointer;
      }

      .right {
        width: 100px;
        height: 50px;
      }

      @include mq($md) {
        position: relative;
        margin-bottom: 1.5em;
      }
    }
    

    .pagination-buttons {
      position: absolute;
      bottom: 0em;
      right: 46%;


      display: flex;
      column-gap: 1em;
      @include mq($xls){
        right: 37%;
      }

      & > div {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid white;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        max-height: 20px;
        max-width: 20px;
      }

      .active {
        border-radius: 50%;
        background: white;
      }

      & > div:hover {
        background: white;
      }

      @include mq($md) {
        margin-bottom: 1em;

        & > div {
          border-radius: 50%;
          border: 1px solid white;
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          max-height: 20px;
          max-width: 20px;
        }
      }
    }
  }

  //boxes
  .box-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 1em;
    row-gap: 1em;

    @include mq($md) {
      margin-bottom: 3em;
      width: 100%;
    }
  }
  .m-top{
    padding-top: 30px !important;
    @include mq($xls) {
      padding-top: 0px !important;
    }
  }
}

.card1 {
  background-image: url("./assets/first.png");
  @include background-img;
}
.moreLink{
  color:#F8107D;
  cursor: pointer;
  z-index: 1000;
  
}
.card-obj {
  min-width: (23.38365%);
  max-width: (23.38365%);
  min-height: ((474/768) * 100vh);
  border-radius: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: grid;
  align-content: space-between;

  .justify-content-end {
    padding-right: 15px;
    padding-top: 15px;
    cursor: pointer;
  }

  .bottom-text-events {
    border-radius: 0px 21px 12px 12px;
    background: transparent;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.15);
    padding: 10px;
   
    .music-title-events {
      font-family: $GTSectraDisplay_Bold;
      font-weight: bold;
      font-size: 0.938em;
      line-height: 1.1em;
      text-align: left;
      color: #e2e2e2;
      margin-bottom: 3px;
    }

    .music-artist-events {
      font-family: $gilroy-medium;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      color: #e2e2e2;
    }
  }

  

  @-moz-document url-prefix() {
    .bottom-text-events {
      background: #B90358E6;;
    }
    }
  @include mq($md) {
    max-width: calc(50% - 0.5em);
    min-width: unset;
    min-width: calc(50% - 0.5em);
    margin-right: unset;
  }

  @include mq($xls) {
    max-width: 100%;
    min-width: unset;
    min-width: calc(100% - 0.5em);
    margin-right: unset;
  }
}


.arrow-circle-right{
  padding: 3px;
  border-radius: 60%;
  font-weight: 800;
  border: 2px solid #F8107D;
  font-size: 10px;
}
// .topper{
//   z-index: 10;
// }
.mobileview{
  min-height:40vh;
  @include mq($xls) {
    min-height:40vh ;
  }
}
.music-details{
  @include mq($xls) {
   padding-left: 15px !important;
  }
}
.margin-3{
  margin-left:1rem ;
  margin-right: 1rem;
  @include mq($xls) {
    margin-right: 0rem !important;
    margin-left: 0.5rem;
   }
}
.mobileremove{
@include mq($xls){
  padding-left: 0px !important;
}
@include mq($md){
  padding-left: 0px !important;
}
}
