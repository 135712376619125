@import "../../../../components/sass/variables.scss";
@import "../../../../components/sass/mixins.scss";

#home {
  padding: 30px;
  min-height: 100vh;
  margin-bottom: 5em;
  .HomeShare{
    position: absolute;
    bottom:10px;
    right:10px;
    // box-shadow: 5px 10px #ffff;
  }
  .icons{
    display: grid;
    min-height: 100vh;
    place-items: center;
    @include mq($md){
      display:none;
    }
  }

  @include mq($xxlg) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @include mq($xlg) {
    padding: 30px;
  }
  @include mq($lg) {
    padding: 15px;
  }

  // background-image: url("https://mir-s3-cdn-cf.behance.net/project_modules/fs/69f812108395731.5fbcf7149219b.jpg");
  // @include background-img;
  // background-color: rgba(0, 0, 0, 0.5);
  // background-blend-mode: multiply;
  background-color: transparent;

  


  .actual-content {
    min-height: calc(100vh - 60px);
    display: grid;
    align-content: center;
    @include mq($md){
      min-height: 100vh;
    }
    .text-content {
      // padding-left: 3%;
      max-width: 50%;
      align-self: center;
      padding-right: 20px;
      @include mq($xlg) {
        max-width: 50%;
        
      }
      @include mq($md) {
        max-width: 100% !important;
        margin-bottom: 20px !important;
        
      }

      @include mq($lg) {
        max-width: 50%;
      }
      @include mq($xls) {
        padding-right: 15px;
        max-width: 100vw;
      }

      .welcome-text {
        font-family: $Heebo_Light;
        font-weight: 300;
        font-size: 0.875em;
        text-align: left;
        color: $white;

        @include mq($lg) {
          font-size: 0.7em;
        }

        @include mq($xls) {
          font-size: 0.875em;
        }
      }

      .the-fat-text {
        margin-bottom: 20px;
        font-family: $GTSectraDisplay_Bold;
        font-weight: bold;
        line-height: 1.1;
        text-align: left;
        color: #e4e4e4;
        font-size: 5em;

        @include mq($xlg) {
          font-size: 4em;
          margin-top:70px;
        }

        @include mq($xls) {
          font-size: 4em;
          margin-bottom: 15px;
        }

        @include mq($xxs) {
          font-size: 1.3em;
        }
      }

      .desc-text {
        font-family: $Heebo_Light;
        font-weight: 300;
        font-size: 1.2em;
        text-align: left;
        color: #e4e4e4;
        margin-bottom: 20px;
        p{
          font-size: 1em;
        }
      }

      .listen-to-music-btn {
        width: 80%;
        height: 45px;
        border-radius: 9px;
        background: transparent;
        border: 1px solid $red;
        display: grid;
        place-items: center;
        font-family: $Heebo_Light;
        font-weight: 400;
        font-size: 1em;
        text-align: left;
        color: $white;
        cursor: pointer;
        margin-bottom: 20px;
        padding-bottom: 5px;

        @include mq($lg) {
          font-size: 1em;
        }

        @include mq($xls) {
          font-size: 1.2em;
          width: 100%;
        }
      }
    }
    .non-main-cta{
      display: flex;
      align-self: center;
      min-width: 100% !important;
      @include mq($xls){
        min-width: 100%;
        max-width: 100%;
      }
    }
    .bottom-links{
     display: flex;
     
     
     @include mq($lg){
      display: block !important;
     }
     @include mq($md){
      display: flex !important;
     }
     @include mq($xls){
      display: block !important;
     }
     
    }
    .edgeleft{
      margin-left: 15% !important;
      
      
      @include mq($lg){
        margin-left: 0% !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      @include mq($md){
        margin-left: 15% !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
      @include mq($xls){
        margin-left: 0% !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }
    .bottom-links {
      // padding-left: 3%;

      @include mq($xls) {
        // opacity: 0;
        // display: none!important;
      }

      .single-link {
        margin-right: 2.5em;
        display: grid;
        place-items: center;
        cursor: pointer;

        svg {
          margin-right: 20px;

          @include mq($lg) {
            height: 20px;
          }
        }

        font-family: $Heebo_Light;
        font-weight: 300;
        font-size: 1em;
        text-align: left;
        color: #fff;

        &:hover {
          @include svg-interaction;
          color: $red;
        }

        @include mq($lg) {
          font-size: 1em;
        }

        @include mq($xls) {
          display: none;
        }
      }
    }
  }
  .back-arrow,.forwardarrow{
    min-width:100px;
    svg{
      height:50px;
      width:auto;
    }
    cursor: pointer;
  }
}
#menu-main-item{
  position: absolute;
  z-index:100;
  left: 0;
  top:10px;
  min-width: 100%;
  transition: all ease-out 0.3s;
  // @include mq($xxlg) {
  //   padding-left: 10vw;
  //   padding-right: 10vw;
  // }
  @include mq($xlg) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include mq($lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mobile-nav {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    // position: absolute;
    @include mq($md) {
      max-width: calc(100vw - 0px);
    }
    .rounded-circle {
      width: 45px;
      height: 45px;
      background: url($root+"/assets/ico/favicon-logo.png");
      @include background-img;
      position: relative;
    }

    .links {
      a {
        font-family: $Heebo_Regular;
        font-weight: normal;
        font-size: 1em;
        text-align: left;
        color: #fff;
        margin-right: 2.5em;
        @include transition;
        align-self: center;

        &:last-child {
          margin-right: 4em;
        }

        &:hover {
          color: $red;
        }

        @include mq($lg) {
          display: none;
        }
      }
    }

    .cta {

      .log-in {
        font-family: $Heebo_Regular;
        font-weight: normal;
        font-size: 1em;
        text-align: left;
        color: $white;
        margin-right: 2.25em;
        cursor: pointer;

        a {
          color: $white;

          &:hover {
            color: $red;
          }
        }

        @include transition;
        align-self: center;

        @include mq($lg) {
          font-size: 0.7em;
        }
        @include mq($xls) {
          display: none !important;
        }
      }
      .register {
        width: 175px;
        height: 45px;
        border-radius: 9px;
        background: $red;
        display: grid;
        place-items: center;
        @extend .log-in;
        color: $white;

        &:hover {
          color: $white;
        }

        @include mq($lg) {
          height: 40px;
        }
      }

      .interview {
        @extend .register;
        background: #10F8D4;
        color:#077777;
      }

      .menu-icon {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 500;
        // right: 15px;
        @include transition;
         display: none;

        @include mq($lg) {
          display: flex;
        }

        .line1,
        .line2,
        .line3 {
          width: 30px;
          height: 2px;
          background-color: $white;
          margin-bottom: 10px;
          @include transition;
        }

        .line2 {
          width: 15px;
          height: 2px;
          align-self: flex-end;
        }
      }
    }

    
  }
}
.guitor{
  display: inline !important;
  font-size: 36px;
  stroke: #fff;
  }
