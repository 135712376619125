@import "../../../../components/sass/mixins.scss";
@import "../../../../components/sass/variables.scss";

.compare-page-wrapper {
  position: relative;
  padding-top: 2vw;
  top: -12vh;
  background-color: #19245A;
  background-size: cover;
  height: max-content;
  width: 100%;

  @include mq($md) {
    padding-top: 10vh;
  }

  .compare-page-content {
    display: block;
    gap: 2vw;
    row-gap: 0;
    width: 93%;
    margin: auto;

    .compare-title {
      @include mq($md) {
        grid-column: 1/3;
      }

      h1 {
        font-family: $SFProDisplay_Bold;
        font-size: 4.9375em;
        line-height: 1.1;

        @include mq($lg) {
          font-size: 4em;
        }

        @include mq($md) {
          font-size: 4.9em;
        }

        @include mq($xls) {
          font-size: 3em;
        }

        @include mq($xms) {
          font-size: 2.9em;
        }
      }

      p {
        font-family: $SFProDisplay_Regular;
        font-size: 1.234375em;
        width: 43vw;
        line-height: 1.16;
        margin-bottom: 18px;

        @include mq($md) {
          width: 98%;
        }

        @include mq($lg) {
          font-size: 1em;
        }

        @include mq($xls) {
          font-size: 1em;
        }
      }
    }

    .compare-number {
      display: flex;
      // width: 50%;
      justify-self: flex-end;
      align-items: center;
      justify-content: space-between;
      margin-right: 40px;

      @include mq($md) {
        // width: 80%;
        justify-content: flex-start;
        justify-self: start;
        grid-column: 1/3;
        margin-bottom: 40px;
        margin-right: 40px;
      }

      .page-number-button {
        border: 2px solid white;
        border-radius: 100%;
        background-color: transparent;
        color: white;
        margin-right: 1.5vw;
        height: 50px;
        width: 50px;
        font-family: $SFProDisplay_Regular;
        font-size: 1.234375em;
        font-weight: 900;

        @include mq($md) {
          height: 40px;
          width: 40px;
          margin-right: 40px;
        }
      }

      .selected {
        background-color: white;
        color: #19245a;
      }
    }

    .slider-container {
      grid-column: 1/3;
      display: grid;
      place-items: center;

      .slider-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2vw;
        width: 93%;
        @include mq($xls){
          display:flex;
          flex-direction: column;
          gap:30px;
        }
      }
    }
  }

  .carousel-status {
    display: none;
  }

  .thumbs-wrapper {
    display: none;
  }

}

#compare {
  .dot {
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border: none;
  }
  .carousel{
    margin-bottom: 80px;
    overflow: unset;
  }
  .control-dots{
    transform: translateY(75px);
  }
}